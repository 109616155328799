
export const fixedData = {
	menu:[
		// {
		// 	id:"01",
		// 	// icon:"el-icon-house",
		// 	icon:"dashboard",
		// 	title:"我的工作台",
		// 	path:"/index",
		// 	sub:null,
		// },
	],
}