<template>
	<el-container class="page-container">
	  <el-aside width="collapse">
		<SideBar :isCollapse="isCollapse"/>
	  </el-aside>
	  <el-container>
	    <el-header><Header @changeCollapse="changeCollapse"/></el-header>
	    <el-main class="page">
			<!-- <Breadcrumb></Breadcrumb> -->
			<router-view v-if="isRouterAlive"></router-view>
			<el-backtop target=".el-main" :visibility-height="300"></el-backtop>
	    </el-main>
	  </el-container>
	</el-container>
</template>

<script>
	import Header from '@/components/Header'
	import SideBar from '@/components/sidebar/SideBar'
	import { mapState } from "vuex"
	export default {
		inject:['app'],
		provide(){
			return {
				layout:this,
				reload: this.reload
			}
		},
		components: {
			Header,
			SideBar
		},
		data() {
			return {
               isRouterAlive:true,
               parentTitle:"",
               currentTitle:"",
			   isCollapse:false,
			}
		},
		created() {

		},
		watch:{
		  $route(to,from){
		  	// console.log(to,from);
		  	// $("main.el-main").scrollTop(0);
		  }
		},
		methods:{
			//页面重新载入函数
			reload () {
				this.isRouterAlive = false;
				this.$nextTick(function () {
					this.isRouterAlive = true
				})
			},
			changeCollapse(){
				this.isCollapse = !this.isCollapse;
			}
		},
	}
</script>

<style>
	.el-menu--popup-bottom-start{
		margin-top: -2px;
	}
	.el-menu-item-group__title{
		padding: 0;
	}
</style>