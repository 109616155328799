<template>
  <el-menu :router="true" :default-active="currentMenu" :unique-opened="true" class="el-menu-vertical-demo" 
  text-color="rgba(255,255,255,0.8)" active-text-color="rgba(255,255,255,1)"  :default-openeds="defaultOpeneds"
  @open="handleOpen" @close="handleClose" @select="handleselect" :collapse="isCollapse" :collapse-transition="false">
    <!-- logo -->
    <div class="logo flex-all">
    	<!-- <i class="icon-cube3 fs_24"></i> -->
		<img src="../../assets/images/logo.png" alt="" style="width: 30px;height: 30px;">
    	<span class="font-lg font-weight-bold" style="margin-left: 5px;" v-show="!isCollapse">{{config.shop_name || '业务考核积分系统'}}</span>
    </div>
    <!-- 为了做递归，所以把Menu抽出来做成一个组件 -->
    <Menu :menuDatas="menuDatas"></Menu>
  </el-menu>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Menu from './Menu.vue'
  import { sideBarData } from './sideBarData.js'
  import { fixedData } from './fixedMenu.js'

  export default {
    provide() {// 向兄弟组件传递重载菜单的方法
      return{
        reloadMenu: this.loadMenu
      }
    },
	inject: ['reload'],
	props: {
	  isCollapse: {
	    default: false,
	    type: Boolean
	  },
	},
    name:"SideBar",
	data () {
		return {
			currentMenu: 'index', // SideBar里面当前高亮菜单的默认值
			menuDatas: [],
			defaultOpeneds:[],
		}
	},
	components: {
	   Menu
	},
	computed: {
	   ...mapGetters([
			 'config'
	   ])
	},
	created () {
		this.$bus.$on("reloadMenu",()=>{
			this.loadMenu()
		})
		this.setCurrentMenu(); // 刷新时，高亮菜单
	},
	watch: {
	    $route(to, from) {
	      this.setCurrentMenu(); // 监听路由切换时，高亮菜单
	    },
	},
    mounted(){
		this.loadMenu();
    },
	methods:{
      // 加载菜单
      loadMenu(){
		  this.axios.post('/manage/operation/menus',{
			  token: this.$cookies.get("token"),
			  role_id:this.$cookies.get("role_id"),
		  }).then(res=>{
			  if(res.status){
				  var fixedMenu = fixedData.menu;
				  this.menuDatas = [...fixedMenu,...res.data];
			  }
		  })
      },
      // 高亮菜单
      setCurrentMenu(){
        var path = this.$route.path.split('#');
        var pathArr = path[0].split('/');
        var num = pathArr.length;
        if(num<4){ // 二级页面
		   this.currentMenu = path[0];
        }else{ // 三级页面
          this.currentMenu = pathArr.slice(0,pathArr.length-1).join("/");
        }
      },
	  handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleselect(key, keyPath){
        if(key == '/index'){
          this.defaultOpeneds = [];
        }
        this.reload();  // 点击侧边栏重新载入页面
      },
	  },
	}
</script>
<style>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
</style>