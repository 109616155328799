<template>
	<div class="page-header h-100 flex align-center">
		<i class="el-icon-s-fold font-lger text-black" @click="isC" v-show="block"></i>
		<i class="el-icon-s-unfold font-lger text-black" @click="isC" v-show="toggle"></i>
		<span class="font text-black ml-2">您好，欢迎来到{{config.shop_name || '管理系统平台'}}！</span>
		<div class="ml-auto flex align-center">
			  <div class="flex align-center ml-4">
				<span class="text-333 mr-2" style="cursor: pointer;" @click="goKtdc">课堂调查</span>
				<span class="text-333 mr-2" style="cursor: pointer;" @click="goto">数字大屏</span>
				<el-dropdown trigger="click">
				  <div class="flex align-center text-black">
					<el-avatar :size="28" :src="avatarUrl"></el-avatar><span class="font ml-1">{{name || '系统用户'}}</span>
				  </div>
				  <el-dropdown-menu slot="dropdown">
					<div @click="logOut"><el-dropdown-item><i class="el-icon-user-solid font-md"></i>退出登录</el-dropdown-item></div>
				  </el-dropdown-menu>
				</el-dropdown>
				<el-dropdown class="mx-1" @command="handleCommand">
				  <span class="el-dropdown-link">
				    {{role_name || '' }}<i class="el-icon-arrow-down el-icon--right"></i>
				  </span>
				  <el-dropdown-menu slot="dropdown">
				    <el-dropdown-item v-for="(item,index) in roles" :key="item.role_id" :command="item.role_id">{{item.name || ''}}</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
				<img src="@/assets/images/exit.png" alt="退出" style="width:20x;height:20px;margin-left:10px;cursor: pointer;" @click="logOut">
			  </div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		name: 'Header',
		inject:['reload'],
		data () {
			return {
				// 头像
				avatarUrl:this.$cookies.get('avatar') && this.$cookies.get('avatar') != 'null' ? this.$cookies.get('avatar') : require("@/assets/images/avatar.jpg"),
				name:this.$cookies.get('userName'),
				toggle: false,//第二个图标默认隐藏
				block: true,//默认显示第一个图标
				role_name:this.VueCookies.get('role_name'),
				roles:[],
			}
		},
		created() {
			this.roles = this.VueCookies.get('roles')
		},
		components: {},
		computed: {
		   ...mapGetters([
		         'config'
		   ])
		},
		methods:{
		  goto(){
			  window.open('http://bbez.dp.ahbkqkj.com', '_blank')
		  },
		  goKtdc(){
			  window.open('http://bbez.ktdc.ahbkqkj.com', '_blank')
		  },
		  logOut(){
			  // this.$cookies.remove("token");
			  this.$cookies.keys().forEach(cookie => {
				  this.$cookies.remove(cookie);
			  });
			  this.$router.push("/login");
		  },
		  isC() {
			  this.$emit('changeCollapse')
		      this.toggle = !this.toggle;
		      this.block = !this.block;
		  },
		  handleCommand(command) {
			 this.axios.post('/manage/admin/reload',{
				 role_id:command
			 }).then(res=>{
				 if(res.status){
					 if(res.data.roles.length > 0){
						let  role_id = ''
					 	res.data.roles.forEach(v=>{
					 		if(v.checked){
								this.role_name = v.name
					 			this.VueCookies.set('role_name',v.name,{expires:7});
					 			this.VueCookies.set('role_id',v.role_id,{expires:7});
					 			role_id = v.role_id
					 		}
					 	})
						this.$bus.$emit("reloadMenu")
						if(role_id == 30){
							this.$cookies.set('back_menu_id', 68);
							this.$router.push("/dashboard");
						}else{
							this.$cookies.set('back_menu_id', 67);
							this.$router.push("/index");
						}
					 }
				 }
			 })
		  }
		},
	}
</script>

<style>
.el-dropdown-link {
    cursor: pointer;
    color: #333;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
